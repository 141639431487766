<template>
    <v-sheet>
        <v-tabs
            v-model="tabComponente"
            dark
            fixed-tabs
            background-color="tnlogAzulEscuro"
        >
            <v-tab v-for="(item, index) in form.tabsConteudo" :key="index">
                {{ index }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabComponente">
            <v-form :ref="formNome">
                <!--
                    DADOS
                 -->
                <v-tab-item eager class="py-4">
                    <v-row>
                        <v-col cols="2" sm="2">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.id_permissao"
                                :form="form"
                                campo="id_permissao"
                            />
                        </v-col>
                        <v-col cols="10" sm="10">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.nome"
                                :form="form"
                                campo="nome"
                            />
                        </v-col>
                        <v-col cols="12" sm="12">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.descricao"
                                :form="form"
                                campo="descricao"
                            />
                        </v-col>
                        <v-col cols="12" sm="12">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.indentificador"
                                :form="form"
                                campo="indentificador"
                            />
                        </v-col>
                        <v-col cols="12" sm="12">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.acao"
                                :form="form"
                                campo="acao"
                            />
                        </v-col>
                        <v-col cols="12" sm="12">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.cor"
                                :form="form"
                                campo="cor"
                            />
                        </v-col>
                        <v-col cols="12" sm="12">
                            <InputTextV2
                                v-model="form.tabsConteudo.dados.icone"
                                :form="form"
                                campo="icone"
                            />
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-form>
        </v-tabs-items>

        <BuscaModal
            ref="BuscaModal"
            @inputAutoCompletaAtualizaValor="inputAutoCompletaAtualizaValor"
            @mensagemAlerta="$emit('mensagemAlerta', $event)"
        />
    </v-sheet>
</template>

<script>
import _ from 'lodash';

import { API_URL } from '@/config/configs';
import { Alerta, MetodoNovoOuAtualizaApi } from '@/helpers/Funcoes.js';
import ApiService from '@/services/ApiService.js';
import DatatableHelper from '@/helpers/Datatables.js';
import {
    Maximo,
    Minimo,
    Obrigatorio,
    Email,
} from '@/helpers/ValidadorCampo.js';

import BuscaModal from '@/components/Default/BuscaModal.vue';
import InputTextV2 from '@/components/Form/InputTextV2.vue';
import TextAreaV2 from '@/components/Form/TextAreaV2.vue';

export default {
    components: {
        BuscaModal,
        InputTextV2,
        TextAreaV2,
    },
    props: ['refBuscaModal'],
    async created() {
        this.formOriginal = _.cloneDeep(this.form);
    },
    watch: {},
    computed: {},
    mounted() {},
    methods: {
        preencheFormulario(conteudo) {
            this.form.tabsConteudo.dados = _.pick(
                conteudo.atributos,
                Object.keys(this.form.tabsConteudo.dados)
            );
            this.form.tabsConteudo.dados.id_permissao = conteudo.id_registro;
        },
        FormataDadosParaEnviarForm() {
            let form = [];
            let dados = Object.assign(this.form.tabsConteudo.dados);
            dados.sensivel = dados.sensivel === 'S' ? true : false;
            dados.id_permissao = this.form.tabsConteudo.dados.id_permissao;
            form = {
                dados,
            };

            return form;
        },
        async novoForm() {
            try {
                if (this.urlNovoRegistro == '') {
                    this.$store.commit('FORM_ACAO', 'form-novo');
                    this.$store.dispatch('painelAbre');

                    return false;
                }

                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.erp[this.moduloNome][this.urlNovoRegistro]
                }`.replace(regexp, function (ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {},
                };

                await ApiService(urlConfig)
                    .then((resposta) => {
                        this.$store.commit('FORM_ACAO', 'form-novo');

                        this.form.complemento = resposta.data.conteudo
                            .complemento
                            ? resposta.data.conteudo.complemento
                            : {};

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async consultaRegistro(id_registro) {
            try {
                this.tabComponente = 'Dados';

                const regexp = /\${([^{]+)}/g;

                let urlConsulta = `${
                    API_URL.erp[this.moduloNome][this.urlConsultaRegistro]
                }`.replace(regexp, function (ignore, key) {
                    return eval(key);
                });

                let urlConfig = {
                    method: 'get',
                    url: `${urlConsulta}`,
                    data: {},
                };

                await ApiService(urlConfig)
                    .then((resposta) => {
                        this.$store.commit('FORM_ACAO', 'form-atualiza');

                        this.preencheFormulario(resposta.data.data);

                        if (resposta.data.data.hasOwnProperty('complemento')) {
                            this.form.complemento = {
                                ...this.form.complemento,
                                ...resposta.data.data.complemento,
                            };
                        }

                        this.$store.dispatch('painelAbreFecha');
                    })
                    .catch((erro) => {
                        throw new Error(erro.message);
                    });
            } catch (e) {
                Alerta(e.message, 'error');
            }
        },
        fechaForm() {
            try {
                this.$store.commit('FORM_ACAO', 'form-grid');
                this.limpaFormulario();
                this.$emit('datatableRecarrega');
                this.$store.dispatch('painelFecha');
                window.scrollTo(0, 0);
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async salvaForm() {
            try {
                if (!this.$refs[this.formNome].validate()) {
                    this.$emit(
                        'mensagemAlerta',
                        'Preencha corretamente todos campos',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Salvar', 'Salvar registro ?', {
                        cor: 'green',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        let conteudoFormulario =
                            this.FormataDadosParaEnviarForm(
                                this.form.tabsConteudo
                            );

                        //console.log('envia form', conteudoFormulario);
                        //return;

                        let urlForm = conteudoFormulario.dados[
                            this.chavePrimaria
                        ]
                            ? `${API_URL.erp[this.moduloNome].salva}/${
                                  conteudoFormulario.dados[this.chavePrimaria]
                              }`
                            : `${API_URL.erp[this.moduloNome].salva}`;

                        let urlConfig = {
                            method: MetodoNovoOuAtualizaApi(
                                conteudoFormulario.dados[this.chavePrimaria]
                            ),
                            url: urlForm,
                            data: conteudoFormulario,
                        };

                        await ApiService(urlConfig)
                            .then((resposta) => {
                                this.$store.commit(
                                    'FORM_ACAO',
                                    'form-atualiza'
                                );

                                this.preencheFormulario(resposta.data.data);

                                conteudoFormulario.dados.id_permissao =
                                    resposta.data.data.id_registro;

                                this.$emit(
                                    'mensagemAlerta',
                                    `Permissao ${
                                        conteudoFormulario.dados[
                                            this.chavePrimaria
                                        ]
                                    } - ${conteudoFormulario.dados.descricao} ${
                                        conteudoFormulario.dados[
                                            this.chavePrimaria
                                        ]
                                            ? 'atualizado'
                                            : 'criado'
                                    } com sucesso`,
                                    'success'
                                );

                                if (
                                    resposta.data.data.hasOwnProperty(
                                        'complemento'
                                    )
                                ) {
                                    this.form.complemento = {
                                        ...this.form.complemento,
                                        ...resposta.data.data.complemento,
                                    };
                                }
                                this.fechaForm();
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        async apagaForm() {
            try {
                let dados = DatatableHelper.datatableGetLinhaSelecionadas(
                    this.moduloNome
                );

                let ids = dados
                    .map(function (dado) {
                        return dado.DT_RowId;
                    })
                    .join();

                if (!ids) {
                    this.$emit(
                        'mensagemAlerta',
                        'Selecione um registro antes de apagar.',
                        'error'
                    );

                    return false;
                }

                await this.$root.$refs.$confirmaAcaoModal
                    .open('Deleta', `Apagar registro(s) ?\n IDS - ${ids}`, {
                        cor: 'red',
                    })
                    .then(async (confirma) => {
                        if (!confirma) {
                            return false;
                        }

                        let urlConfig = {
                            method: 'DELETE',
                            url: `${API_URL.erp[this.moduloNome].apaga}/${ids}`,
                            params: { [this.chavePrimaria]: ids },
                        };

                        let dados = await ApiService(urlConfig)
                            .then((resposta) => {
                                this.$emit('datatableRecarrega');

                                this.$emit(
                                    'mensagemAlerta',
                                    `Registro ${ids} Deletado com sucesso`,
                                    'success'
                                );
                            })
                            .catch((erro) => {
                                throw new Error(erro.message);
                            });
                    });
            } catch (e) {
                this.$emit('mensagemAlerta', e.message, 'error');
            }
        },
        limpaFormulario() {
            //this.$refs[this.formNome].reset();

            this.form = _.cloneDeep(this.formOriginal);
        },
        atualizaFormComplemento(complemento) {
            Object.keys(this.form.complemento).map((chave, idx) => {
                if (complemento[chave]) {
                    this.form.complemento[chave] = complemento[chave];
                }
            });

            this.formOriginal.complemento = this.form.complemento;
        },
        abrePesquisa(
            objetoClicado,
            objetoRetorno,
            formatoRetornado,
            titulo,
            datatableBusca
        ) {
            this.$refs['BuscaModal'].abreModal = true;
            this.$refs['BuscaModal'].titulo = titulo;
            this.$refs['BuscaModal'].objetoClicado = objetoClicado;
            this.$refs['BuscaModal'].objetoRetorno = objetoRetorno;
            this.$refs['BuscaModal'].formatoRetornado = formatoRetornado;
            this.$refs['BuscaModal'].datatableBusca = datatableBusca;
        },
        inputAutoCompletaAtualizaValor(objectoOpcoes, valorDefinido) {
            this.$refs['BuscaModal'].objetoClicado.atualizaValor(
                objectoOpcoes,
                valorDefinido
            );
        },
    },
    data() {
        return {
            apiUrl: API_URL,
            tabComponente: 'Dados',
            formNome: 'formPermissao',
            moduloNome: 'permissao',
            chavePrimaria: 'id_permissao',
            urlNovoRegistro: '',
            urlConsultaRegistro: 'busca',
            formOriginal: {},
            form: {
                tabsConteudo: {
                    dados: {
                        id_permissao: '',
                        descricao: '',
                        nome: '',
                        indentificador: '',
                        acao: '',
                        cor: '',
                        icone: '',
                    },
                },nome: '',
                        indentificador: '',
                        acao: '',
                        cor: '',
                        icone: '',
                complementoOriginal: {},
                complemento: {},
                configuracao: {
                    campo: {},
                    campoConfiguracao: {
                        id_permissao: {},
                        descricao: {},
                        nome: {},
                        indentificador: {},
                        acao: {},
                        cor: {},
                        icone: {},
                    },
                    tamanho: {
                        id_permissao: {},
                        descricao: { min: 2, max: 100 },
                        nome: {min: 2, max: 50},
                        indentificador: {min: 2, max: 100},
                        acao: {min: 2, max: 30},
                        cor: {min: 2, max: 10},
                        icone: {min: 2, max: 50},
                    },
                    regra: {
                        id_permissao: [],
                        descricao: [Obrigatorio(), Minimo(2), Maximo(100)],
                        nome: [Obrigatorio(), Minimo(2), Maximo(50)],
                        indentificador: [Obrigatorio(), Minimo(2), Maximo(100)],
                        acao: [Obrigatorio(), Minimo(2), Maximo(30)],
                        cor: [Obrigatorio(), Minimo(2), Maximo(10)],
                        icone: [Obrigatorio(), Minimo(2), Maximo(50)],
                    },
                    placeholder: {
                        id_permissao: 'ID',
                        descricao: 'Descrição',
                        nome: 'Nome',
                        indentificador: 'Indentificador',
                        acao: 'Ação',
                        cor: 'Cor',
                        icone: 'Ícone',
                    },
                    colunas: {},
                    obrigatorio: {
                        id_permissao: false,
                        descricao: true,
                        nome: true,
                        indentificador: true,
                        acao: true,
                        cor: true,
                        icone: true,
                    },
                    desativado: {
                        id_permissao: true,
                        descricao: false,
                        nome: false,
                        indentificador: false,
                        acao: false,
                        cor: false,
                        icone: false,
                    },
                    dataTableVuetify: {},
                },
            },
        };
    },
};
</script>

<style scoped>
</style>
